import styles from "./Districts.module.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useState } from "react";
import backr from "./Backr.svg"
import sel2 from "./Sel2.svg"
import circulob from "./Circulob.svg"
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'

export function District3() {

  const isMobile = window.innerWidth <= 500;

    const tabItems = [
        {
          id: 1,
          title: 'STEP 1',
          icon: 'tabitem__icon fas fa-child',
          content: <div className={styles.tabM}><h1>First Aid Kit</h1>
          <p className={styles.tabP}>- Economic and technological support for projects and emerging artists who do not know how to develop on the web3. When we say that Kahiru is different, we mean it, because our goal is to bring value to the whole community. With this platform (helped by the votes of the Kahiru council (DAO)) we will give support, visibility and assistance to projects with potential that do not have enough resources to be in the place they deserve. Every project that goes through this system will have the Kahiru certifikado, a red K that can be integrated in their websites, networks and dossiers, showing a seal of confidence for the whole world.</p>

          </div>,
        },
        {
          id: 2,
          title: 'STEP 2',
          icon: 'tabitem__icon fas fa-users',
          content: <div className={styles.tabM}><h1>Divine Manuscript</h1>
          <p className={styles.tabP}>- The time has come. For those who could manage to claim the cover of the comic (or buy it) and at the same time are owners of one of the 7222 NFT of the genesis collection, they will have the possibility to have in their possession the physical comic of Kahiru. A comic book that will tell the stories of Ruy and the fight between the 5 factions. It will not be a comic book for sale, so if you keep it in its unique and special packaging, it may become a unique collector's item.</p>
          </div>,
        },
        {
          id: 3,
          title: 'STEP 3',
          icon: 'tabitem__icon fas fa-network-wired',
          content: <div className={styles.tabM}><h1>The Sixth</h1>
          <p className={styles.tabP}>- We are open to anything that can bring value to our community. For this, we will allocate part of the resources in the search for new lines of action that bring value to the community, always supported by our board (DAO) to be fully democratic actions.</p>
          </div>
    },
      ];
    
      const [active, setActive] = useState(1);

      const scrollToBottom = () =>{ 
        window.scrollTo({ 
          top: document.documentElement.scrollHeight, 
          behavior: 'smooth'
        }); 
      };
      
      const TabsComponent = () => {
        
        return (
          <div className={styles.wrapper}>
            <div className={styles.tabs}>
              {tabItems.map(({ id, icon, title }) =>
              <TabItemComponent
                 key={title}
                 icon={icon}
                 title={title}
                 onItemClicked={() => setActive(id)}
                 isActive={active === id}
               />
            )}
            </div>
            <div className={styles.content}>
              {tabItems.map(({ id, content }) => {
                return active === id ? content : ''
              })}
            </div>
           </div>
        )
      }
      
      const TabItemComponent = ({
        icon = '',
        title = '',
        onItemClicked = () => console.error('You passed no action to the component'),
        isActive = false,
      }) => {
        return (
          <div onClick={onItemClicked}>
          </div>
        )
      };

      if (isMobile){
        return (
          <div className={styles.contenedor6}>
            <div className={styles.centw}>
            <div className={styles.tarjeta}>
              <h2 className={styles.titulotarjeta}>DAO<img style={{marginLeft: '0.7rem'}} src={circulob} alt="" /></h2>
              <p className={styles.ptarjeta}>A fair system is one in which everyone decides what to do. Therefore, we will make available to all people within the project, the possibility to vote in order to decide the direction of Kahiru.</p>
              <p className={styles.ptarjeta}>What companies to collaborate with, what gifts to offer to the community, what projects to host on the platform and much more, all at the disposal of you, the community.</p>
            </div>
            <div className={styles.tarjeta}>
              <h2 className={styles.titulotarjeta}>Divine Manuscript<img style={{marginLeft: '0.7rem'}} src={circulob} alt="" /></h2>
              <p className={styles.ptarjeta}>The time has come. For those who could manage to claim the cover of the comic (or buy it) and at the same time are owners of one of the 7222 NFT of the genesis collection, they will have the possibility to have in their possession the physical comic of Kahiru. A comic book that will tell the stories of Ruy and the fight between the 5 factions. It will not be a comic book for sale, so if you keep it in its special packaging, it may become a unique collector's item.</p>
            </div>
            <div className={styles.tarjeta}>
              <h2 className={styles.titulotarjeta}>First Aid Kit<img style={{marginLeft: '0.7rem'}} src={circulob} alt="" /></h2>
              <p className={styles.ptarjeta}>Economic and technological support for projects and emerging artists who do not know how to develop on the web3. When we say that Kahiru is different, we mean it, because our goal is to bring value to the whole community. With this platform (helped by the votes of the Kahiru council (DAO)) we will give support, visibility and assistance to projects with potential that do not have enough resources to be in the place they deserve. Every project that goes through this system will have the Kahiru certificado, a red K that can be integrated in their websites, networks and dossiers, showing a seal of confidence for the whole world.</p>
            </div>
            <div className={styles.tarjeta}>
              <h2 className={styles.titulotarjeta}>The Sixth<img style={{marginLeft: '0.7rem'}} src={circulob} alt="" /></h2>
              <p className={styles.ptarjeta}>We are open to anything that can bring value to our community. For this, we will allocate part of the resources in the search for new lines of action that bring value to the community, always supported by our board (DAO) to be fully democratic actions.</p>
            </div>
            </div>
          </div>
        )
      }

      else {

    return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: [0, 1] }} transition={{ duration: 0.8 }}>
      <div className={styles.containercol}>
        <div className={styles.divcol1}>
          <div className={styles.containercol2}>
            <div className={styles.divcol3}><Link to="/the-way"><img className={styles.back2} src={backr} alt=""></img></Link></div>
            <div className={styles.divcol4}><p className={styles.ptarjeta2}>DISTRICT 3</p></div>
          </div>
          <div className={styles.containertarjetas}>
            <div style={{ width: '110%' }}  className={styles.tarjeta}>
              <h2 className={styles.titulotarjeta}>DAO<img style={{marginLeft: '0.7rem'}} src={circulob} alt="" /></h2>
              <p className={styles.ptarjeta}>A fair system is one in which everyone decides what to do. Therefore, we will make available to all people within the project, the possibility to vote (with a force or another depending on the tokens you have) to decide the direction of Kahiru. From what companies to collaborate with, what gifts to offer to the community, what projects to host on the platform and much more, all at the disposal of you, the community.</p>
            </div>
            <div style={{ width: '125%' }}  className={styles.tarjeta}>
              <h2 className={styles.titulotarjeta}>Divine Manuscript<img style={{marginLeft: '0.7rem'}} src={circulob} alt="" /></h2>
              <p className={styles.ptarjeta}>The time has come. For those who could manage to claim the cover of the comic (or buy it) and at the same time are owners of one of the 7222 NFT of the genesis collection, they will have the possibility to have in their possession the physical comic of Kahiru. A comic book that will tell the stories of Ruy and the fight between the 5 factions. It will not be a comic book for sale, so if you keep it in its unique and special packaging, it may become a unique collector's item.</p>
            </div>
            <div style={{ width: '140%' }}  className={styles.tarjeta}>
              <h2 className={styles.titulotarjeta}>First Aid Kit<img style={{marginLeft: '0.7rem'}} src={circulob} alt="" /></h2>
              <p className={styles.ptarjeta}>Economic and technological support for projects and emerging artists who do not know how to develop on the web3. When we say that Kahiru is different, we mean it, because our goal is to bring value to the whole community. With this platform (helped by the votes of the Kahiru council (DAO)) we will give support, visibility and assistance to projects with potential that do not have enough resources to be in the place they deserve. Every project that goes through this system will have the Kahiru certificate, a red K that can be integrated in their websites, networks and dossiers, showing a seal of confidence for the whole world.</p>
            </div>
            <div style={{ width: '110%' }}  className={styles.tarjeta}>
              <h2 className={styles.titulotarjeta}>The Sixth<img style={{marginLeft: '0.7rem'}} src={circulob} alt="" /></h2>
              <p className={styles.ptarjeta}>We are open to anything that can bring value to our community. For this, we will allocate part of the resources in the search for new lines of action that bring value to the community, always supported by our board (DAO) to be fully democratic actions.</p>
            </div>
          </div>
        </div>
        <div className={styles.divcol2}>
          <img className={styles.foto3} src={sel2} alt="" />
        </div>
      </div>
      
 </motion.div>
    )}
}