import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import { LandingPage } from './Pages/LandingPage';
import { Roadmap } from './Pages/Roadmap';
import { Gallery } from './Pages/Gallery';
import { Navbar1 } from './Components/Navbar';
import { Footer } from './Components/Footer';
import { useMetaMask } from 'metamask-react';
import { Team } from './Pages/Team';
import { District1 } from './Pages/District1';
import { District2 } from './Pages/District2';
import { District3 } from './Pages/District3';
import ReactGA from 'react-ga';
import { Navigate } from 'react-router-dom';
import WebFont from 'webfontloader';

/**
 * Initialize Google Analytics with the given tracking ID.
 * @param trackingID - The tracking ID to use.
 * @returns None
 */
const TRACKING_ID = 'G-M452XCWKZE'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export function App() {
  const { status, connect, account, chainId, ethereum } = useMetaMask();

  /**
   * A React hook that sends a pageview to Google Analytics.
   * @returns None
   */
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  WebFont.load({
    custom: {
      families: ['Bebas Neue', 'Quicksand'],
    },
  });

  /**
   * A hook that returns the current MetaMask status.
   * @returns A MetaMask status object.
   */
  function About() {
    // 👇️ redirect to external URL
    window.location.replace('https://staking.kahiru.io');

    return null;
  }

  function Theway() {
    // 👇️ redirect to external URL
    window.location.replace('https://discord.com/channels/925846470240894996/941306136500527204');

    return null;
  }

  function Lore() {
    // 👇️ redirect to external URL
    window.location.replace('https://discord.com/channels/925846470240894996/940261639121424465');

    return null;
  }

  return (
    <Router>
      <Navbar1></Navbar1>
      <header></header>
      <main>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Navigate to={'/'} />} />
          <Route path="/kahiru" element={<Roadmap />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/the-way" element={<Theway />} />
          <Route path="/district1" element={<District1 />} />
          <Route path="/district2" element={<District2 />} />
          <Route path="/district3" element={<District3 />} />
          <Route path="/stash" element={<About />} />
          <Route path="/profile" element={<About />} />
          <Route path="/profilelogin" element={<About />} />
          <Route path="/lore" element={<Lore />} />
          <Route path="/team" element={<Team />} />
          <Route path="/staking" element={<About />} />
        </Routes>
      </main>
      <footer>
        {/* {isDisabled === false && <Footer />} */}
        <Footer></Footer>
      </footer>
    </Router>
  );
}
