import styles from "./Tabs.module.css";
import { useState, useMemo } from "react";
import {motion} from 'framer-motion/dist/framer-motion'


/**
 * A component that represents a tab in the tabs component.
 * @param props - The props for the component.
 * @returns A component that represents a tab in the tabs component.
 */
function Tab(props) {
    return (
        <div className={styles.tabs__content}>
            {/* <h3>{props.tab.title}</h3> */} 
            <div className={styles.scroll1}>
                <div className={styles.p2}>
                    {props.tab.text}
                    <div className={styles.espacio}><a href=""></a></div>
                    
                </div>
            
            </div>
            <div className={styles.degradado1}><a href=""></a></div>
        </div>
    );
}

/**
 * A component that renders the navigation tabs for the site.
 * @param props - The props for the component. 
 * @returns A component that renders the navigation tabs for the site.
 */
function Navigation(props) {

    return (
        <div className={styles.centrar}>
            <ul className={styles.tabs__nav}>
                {props.tabs.map((item) => (
                    <li key={item.id} className={styles.tabs__item}>
                        <motion.div whileHover={{ scale: 1.05, transition: { duration: 0.2 }, }} whileTap={{ scale: 0.9 }}>
                            <img onClick={() => props.onNavClick(item.id)} className={props.activeTabId !== item.id ? [styles.tabs__button] : [styles.inactive]} src={item.name1} alt="" />
                            <img onClick={() => props.onNavClick(item.id)} className={props.activeTabId === item.id ? [styles.tabs__button] : [styles.inactive]} src={item.name2} alt="" />
                        </motion.div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

/**
 * A component that renders a tabbed interface.
 * @param props - The props for the component.
 * @returns A tabbed interface.
 */
export function Tabs(props) {
    const [activeTabId, setActiveTab] = useState(props.tabs[0].id);

    const activeTab = useMemo(() => (
        props.tabs.find((tab) => (
            tab.id === activeTabId
        ))
    ), [activeTabId, props.tabs]);

    return (

        <div className={styles.tabs2}>
            <Navigation tabs={props.tabs} onNavClick={setActiveTab}
                activeTabId={activeTabId} />
            <Tab tab={activeTab} />
        </div>
    );
}