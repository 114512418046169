import styles from "./Navbar.module.css";
import kahiru from "./kahiru.svg"
import kahiruW from "./KahiruW.svg"
import discord from "./Discord.svg"
import twitter from "./Twiter.svg"
import wallet from "./wallet.svg"
import candado from "./Candado.svg"
import circulo from "./Circulo.svg"
import opensear from "./Opensearr.svg"
import twitterr from "./Twitterr.svg"
import discordr from "./Discordr.svg"
import walletr from "./Walletr.svg"
import circulitow from "./CirculitoW.svg"
import { Roadmap } from "../Pages/Roadmap"
import { LandingPage } from "../Pages/LandingPage"
import { Gallery } from "../Pages/Gallery"
import close from "./close.svg"
import { Link, NavLink, useLocation } from "react-router-dom";
import { ExternalLink } from 'react-external-link';
import ResponsiveMenu from 'react-responsive-navbar';
import opensead from "./Opensea.svg"

export function Navbar1() {

    /**
     * Returns the current location of the page.
     * @returns The current location of the page.
     */
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");


    // White Navbar
    // || splitLocation[1] === "district1" || splitLocation[1] === "district2" || splitLocation[1] === "district3"

    if (splitLocation[1] === "lore") {

        return (
            <ResponsiveMenu
                menuOpenButton={<div className={splitLocation[1] === "lore" ? [styles.zinr] : [styles.zin]}><img className={styles.img2} src={kahiruW} alt="" /></div>}
                menuCloseButton={<div className={splitLocation[1] === "lore" ? [styles.zinr] : [styles.zin]}><img className={styles.img2} src={close} alt="" /></div>}
                changeMenuOn="500px"
                largeMenuClassName="large-menu-classname"
                smallMenuClassName={styles.l}
                menu={
                    <nav className={styles.navr}>
                        <ul className={styles.navbarr}>
                            <div className={styles.nav2r}>
                                <li className={styles.list12}><Link to="/" component={LandingPage} activeClassName="active" className={styles.nolink} ><img className={styles.imgnav1} src={kahiruW} alt="" /></Link></li>
                                <div className={styles.nav4}>
                                    <li className={styles.list1r}>
                                        <NavLink to="/" component={LandingPage} className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "" ? [styles.activer] : [styles.inactiver]}>Home</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                    <img className={splitLocation[1] === "" ? [styles.circulito] : [styles.circulito_inactive]} src={circulo} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                    <li className={styles.list1r}>
                                        <NavLink to="/the-way" component={Gallery} className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "the-way" || splitLocation[1] === "district1" || splitLocation[1] === "district2" || splitLocation[1] === "district3" ? [styles.activer] : [styles.inactiver]}>The way</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                <img className={splitLocation[1] === "the-way" || splitLocation[1] === "district1" || splitLocation[1] === "district2" || splitLocation[1] === "district3" ? [styles.circulito1] : [styles.circulito_inactive]} src={circulo} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                    <li className={styles.list1r}>
                                        <a className={styles.inactiver} >Gallery</a><img className={styles.candado} src={candado} alt="" />
                                    </li>
                                    <li className={styles.list1r}>
                                        <NavLink to="/kahiru" component={Roadmap} className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "kahiru" || splitLocation[1] === "team" ? [styles.activer] : [styles.inactiver]}>Kahiru</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                    <img className={splitLocation[1] === "kahiru" || splitLocation[1] === "lore" || splitLocation[1] === "team" ? [styles.circulito] : [styles.circulito_inactive]} src={circulitow} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                    <li className={styles.list1r}>
                                        <NavLink to="/stash" className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "stash" ? [styles.activer] : [styles.inactiver]}>Marketplace</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                    <img className={splitLocation[1] === "stash" ? [styles.circulito] : [styles.circulito_inactive]} src={circulitow} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                </div>
                                
                                <li className={styles.list13} ><ExternalLink href="https://discord.com/invite/kahiru" className={styles.nostyle}><img className={styles.imgnav} src={discordr} alt="" /></ExternalLink></li>
                                <li className={styles.list14} ><img className={styles.imgnav} src={opensear} alt="" /></li>
                                <li className={styles.list14} ><ExternalLink href="https://twitter.com/kahirunft" className={styles.nostyle}><img className={styles.imgnav} src={twitterr} alt="" /></ExternalLink></li>
                                <li className={styles.list15} ><NavLink to="/profilelogin"><button className={styles.boton1} ><img className={styles.imgnavw} src={walletr} alt="" /></button></NavLink></li>
                                {/* <li className={styles.list14} ><div><h3>{estado}</h3></div></li> */}
                            </div>
                            <div className={styles.nav5}>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/" component={LandingPage} className={styles.nolink} ><a className={splitLocation[1] === "" ? [styles.active] : [styles.inactiver]}>Home</a></NavLink></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/the-way" component={Gallery} className={styles.nolink} ><a className={splitLocation[1] === "the-way" ? [styles.active] : [styles.inactiver]}>The way</a></NavLink></li>
                                <li className={styles.list1}><a className={styles.inactiver}>Gallery</a><img className={styles.candado} src={candado} alt="" /></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/kahiru" component={Roadmap} className={styles.nolink} ><a className={splitLocation[1] === "kahiru" ? [styles.active] : [styles.inactiver]}>Kahiru</a></NavLink></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/stash" className={styles.nolink} ><a className={splitLocation[1] === "stash" ? [styles.active] : [styles.inactiver]}>Marketplace</a></NavLink></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/profilelogin" className={styles.nolink} ><a className={splitLocation[1] === "profile" ? [styles.active] : [styles.inactiver]}>Profile</a></NavLink></li>
                            </div>
                        </ul>
                    </nav>
                }
            />
        );
    }

    if (splitLocation[1] === "kahiru") {

        return (
            <ResponsiveMenu
                menuOpenButton={<div className={splitLocation[1] === "lore" ? [styles.zinr] : [styles.zin]}><img className={styles.img2} src={kahiru} alt="" /></div>}
                menuCloseButton={<div className={splitLocation[1] === "lore" ? [styles.zinr] : [styles.zin]}><img className={styles.img2} src={close} alt="" /></div>}
                changeMenuOn="500px"
                largeMenuClassName="large-menu-classname"
                smallMenuClassName={styles.l}
                menu={
                    <nav>
                        <ul className={styles.navbar}>
                            <div className={styles.nav2}>
                                <li className={styles.list12}><Link to="/" component={LandingPage} activeClassName="active" className={styles.nolink} ><img className={styles.imgnav1} src={kahiruW} alt="" /></Link></li>
                                <div className={styles.nav4}>
                                    <li className={styles.list1}>
                                        <NavLink to="/" component={LandingPage} className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "" ? [styles.active] : [styles.inactive]}>Home</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                    <img className={splitLocation[1] === "" ? [styles.circulito] : [styles.circulito_inactive]} src={circulo} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                    <li className={styles.list1}>
                                        <NavLink to="/the-way" component={Gallery} className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "the-way" || splitLocation[1] === "district1" || splitLocation[1] === "district2" || splitLocation[1] === "district3" ? [styles.active] : [styles.inactive]}>The way</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                <img className={splitLocation[1] === "the-way" || splitLocation[1] === "district1" || splitLocation[1] === "district2" || splitLocation[1] === "district3" ? [styles.circulito1] : [styles.circulito_inactive]} src={circulo} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                    <li className={styles.list1}>
                                        <a>Gallery</a><img className={styles.candado} src={candado} alt="" />
                                    </li>
                                    <li className={styles.list1}>
                                        <NavLink to="/kahiru" component={Roadmap} className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "kahiru" || splitLocation[1] === "team" ? [styles.active] : [styles.inactive]}>Kahiru</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                    <img className={splitLocation[1] === "kahiru" || splitLocation[1] === "lore" || splitLocation[1] === "team" ? [styles.circulito] : [styles.circulito_inactive]} src={circulo} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                    <li className={styles.list1}>
                                        <NavLink to="/stash" className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "stash" ? [styles.active] : [styles.inactive]}>Marketplace</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                    <img className={splitLocation[1] === "stash" ? [styles.circulito] : [styles.circulito_inactive]} src={circulo} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                </div>
                                <li className={styles.list13} ><ExternalLink href="https://discord.com/invite/kahiru" className={styles.nostyle}><img className={styles.imgnav} src={discord} alt="" /></ExternalLink></li>
                                <li className={styles.list14} ><ExternalLink href="https://opensea.io/collection/kahiru" className={styles.nostyle}><img className={styles.imgnav} src={opensead} alt="" /></ExternalLink></li>
                                <li className={styles.list14} ><ExternalLink href="https://twitter.com/kahirunft" className={styles.nostyle}><img className={styles.imgnav} src={twitter} alt="" /></ExternalLink></li>
                                <li className={styles.list15} ><NavLink to="/profilelogin"><button className={styles.boton1}><img className={styles.imgnavw} src={wallet} alt="" /></button></NavLink></li>
                                {/* <li className={styles.list14} ><div><h3>{estado}</h3></div></li> */}
                            </div>
                            <div className={styles.nav5}>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/" component={LandingPage} className={styles.nolink} ><a className={splitLocation[1] === "" ? [styles.active] : [styles.inactive]}>Home</a></NavLink></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/the-way" component={Gallery} className={styles.nolink} ><a className={splitLocation[1] === "the-way" ? [styles.active] : [styles.inactive]}>The way</a></NavLink></li>
                                <li className={styles.list1}><a>Gallery</a><img className={styles.candado} src={candado} alt="" /></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/kahiru" component={Roadmap} className={styles.nolink} ><a className={splitLocation[1] === "kahiru" ? [styles.active] : [styles.inactive]}>Kahiru</a></NavLink></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/stash" className={styles.nolink} ><a className={splitLocation[1] === "stash" ? [styles.active] : [styles.inactive]}>Marketplace</a></NavLink></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/profilelogin" className={styles.nolink} ><a className={splitLocation[1] === "profile" ? [styles.active] : [styles.inactive]}>Profile</a></NavLink></li>
                            </div>
                        </ul>
                    </nav>
                }
            />
        );
    }

    /**
     * A responsive menu that changes based on the screen size. 
     * @param menuOpenButton - The button that opens the menu. 
     * @param menuCloseButton - The button that closes the menu. 
     * @param changeMenuOn - The screen size that the menu changes on. 
     * @param largeMenuClassName - The class name of the menu when it is large. 
     * @param smallMenuClassName - The class name of the menu when it is small. 
     * @param menu - The menu that is displayed. 
     * @returns A responsive menu that changes based on the screen size. 
     */
    else {

        return (
            <ResponsiveMenu
                menuOpenButton={<div className={splitLocation[1] === "lore" ? [styles.zinr] : [styles.zin]}><img className={styles.img2} src={kahiru} alt="" /></div>}
                menuCloseButton={<div className={splitLocation[1] === "lore" ? [styles.zinr] : [styles.zin]}><img className={styles.img2} src={close} alt="" /></div>}
                changeMenuOn="500px"
                largeMenuClassName="large-menu-classname"
                smallMenuClassName={styles.l}
                menu={
                    <nav className={styles.nav}>
                        <ul className={styles.navbar}>
                            <div className={styles.nav2}>
                                <li className={styles.list12}><Link to="/" component={LandingPage} activeClassName="active" className={styles.nolink} ><img className={styles.imgnav1} src={kahiru} alt="" /></Link></li>
                                <div className={styles.nav4}>
                                    <li className={styles.list1}>
                                        <NavLink to="/" component={LandingPage} className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "" ? [styles.active] : [styles.inactive]}>Home</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                    <img className={splitLocation[1] === "" ? [styles.circulito] : [styles.circulito_inactive]} src={circulo} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                    <li className={styles.list1}>
                                        <NavLink to="/the-way" component={Gallery} className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "the-way" || splitLocation[1] === "district1" || splitLocation[1] === "district2" || splitLocation[1] === "district3" ? [styles.active] : [styles.inactive]}>The way</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                <img className={splitLocation[1] === "the-way" || splitLocation[1] === "district1" || splitLocation[1] === "district2" || splitLocation[1] === "district3" ? [styles.circulito1] : [styles.circulito_inactive]} src={circulo} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                    <li className={styles.list1}>
                                        <a>Gallery</a><img className={styles.candado} src={candado} alt="" />
                                    </li>
                                    <li className={styles.list1}>
                                        <NavLink to="/kahiru" component={Roadmap} className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "kahiru" || splitLocation[1] === "team" ? [styles.active] : [styles.inactive]}>Kahiru</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                    <img className={splitLocation[1] === "kahiru" || splitLocation[1] === "lore" || splitLocation[1] === "team" ? [styles.circulito] : [styles.circulito_inactive]} src={circulo} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                    <li className={styles.list1}>
                                        <NavLink to="/stash" className={styles.nolink} >
                                            <div className={styles.navtito}>
                                                <div className={styles.childnavtito}>
                                                    <a className={splitLocation[1] === "stash" ? [styles.active] : [styles.inactive]}>Marketplace</a>
                                                </div>
                                                <div className={styles.childnavtito}>
                                                    <img className={splitLocation[1] === "stash" ? [styles.circulito] : [styles.circulito_inactive]} src={circulo} alt="" />
                                                </div>
                                            </div> 
                                        </NavLink>
                                    </li>
                                </div>
                                <li className={styles.list13} ><ExternalLink href="https://discord.com/invite/kahiru" className={styles.nostyle}><img className={styles.imgnav} src={discord} alt="" /></ExternalLink></li>
                                <li className={styles.list14} ><ExternalLink href="https://opensea.io/collection/kahiru" className={styles.nostyle}><img className={styles.imgnav} src={opensead} alt="" /></ExternalLink></li>
                                <li className={styles.list14} ><ExternalLink href="https://twitter.com/kahirunft" className={styles.nostyle}><img className={styles.imgnav} src={twitter} alt="" /></ExternalLink></li>
                                <li className={styles.list15} ><NavLink to="/profilelogin"><button className={styles.boton1}><img className={styles.imgnavw} src={wallet} alt="" /></button></NavLink></li>
                                {/* <li className={styles.list14} ><div><h3>{estado}</h3></div></li> */}
                            </div>
                            <div className={splitLocation[1] === "lore" ? [styles.nav5r] : [styles.nav5]}>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/" component={LandingPage} className={styles.nolink} ><a className={splitLocation[1] === "" ? [styles.active] : [styles.inactive]}>Home</a></NavLink></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/the-way" component={Gallery} className={styles.nolink} ><a className={splitLocation[1] === "the-way" ? [styles.active] : [styles.inactive]}>The way</a></NavLink></li>
                                <li className={styles.list1}><a>Gallery</a><img className={styles.candado} src={candado} alt="" /></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/kahiru" component={Roadmap} className={styles.nolink} ><a className={splitLocation[1] === "kahiru" ? [styles.active] : [styles.inactive]}>Kahiru</a></NavLink></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/stash" className={styles.nolink} ><a className={splitLocation[1] === "stash" ? [styles.active] : [styles.inactive]}>Marketplace</a></NavLink></li>
                                <li className={styles.list1}><NavLink onClick={{ showMenu: false }} to="/profilelogin"  className={styles.nolink} ><a className={splitLocation[1] === "profile" ? [styles.active] : [styles.inactive]}>Profile</a></NavLink></li>
                            </div>
                        </ul>
                    </nav>
                }
            />
        );
    }
}
