import styles from "./Districts.module.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useState, useRef } from "react";
import backr from "./Backr.svg"
import sel1 from "./Sel1.svg"
import circulov from "./Circulov.svg"
import circulon from "./Circulon.svg"
import circulob from "./Circulob.svg"
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'

const scrollToRef = (ref) => {
  window.scrollTo(0, ref.current.offsetBottom)
  //console.log(ref)
}

export function District1() {
  const isMobile = window.innerWidth <= 500;
    const tabItems = [
        {
          id: 1,
          title: 'STEP 1',
          icon: 'tabitem__icon fas fa-child',
          content: <div className={styles.tabM}><h1>Heroes of Ruy</h1><p className={styles.tabP}>- Welcome to the Genesis collection. Our first collection in the kahiru universe, which introduces the 5 factions and the 7222 warriors.</p></div>,
        },
        {
          id: 2,
          title: 'STEP 2',
          icon: 'tabitem__icon fas fa-users',
          content: <div className={styles.tabM}><h1>Reveal day</h1><p className={styles.tabP}>- We bring together DJs from Ultra Music Festival and Tomorrowland for this unique occasion, with a streaming of more than 5 hours. We will have the opportunity to enjoy the music of</p>
          <p className={styles.tabP}>- We are waiting for you all inside as we will have surprises for those who want to join us at the party.</p>
          </div>,
        },
        {
          id: 3,
          title: 'STEP 3',
          icon: 'tabitem__icon fas fa-network-wired',
          content: <div className={styles.tabM}><h1>Ruys Vault</h1>
          <p className={styles.tabP}>- We will start the creation of the Kahiru community wallet. In it, we will store part of the mint and the royaltys from the secondary market sales. With it, we will carry out the actions of the future, bringing value to the whole Kahiru universe.</p>
          <p>- Citzens Merit</p>
          <p className={styles.tabP}>- From the first day of the mining, a period of 89 days will be opened to be able to ovetense the sacred manuscript. Any holder who holds his NFT for at least 30 days will be able to get it. This manuscript will be listed in opensea (with a maximum of 7222) and will be delivered to the different holders. With it, you will be able to get the cover of Kahiru's comic book.</p>
          </div>
    },
      ];


      
      
      const myRef = useRef(null)
      const executeScroll = () => scrollToRef(myRef)
      
      const [active, setActive] = useState(1);

      const scrollToBottom = () =>{ 
        window.scrollTo({ 
          top: document.documentElement.scrollHeight, 
          behavior: 'smooth'
        }); 
      };
      
      const TabsComponent = () => {
        
        return (
          <div className={styles.wrapper}>
            <div className={styles.tabs}>
              {tabItems.map(({ id, icon, title }) =>
              <TabItemComponent
                 key={title}
                 icon={icon}
                 title={title}
                 onItemClicked={() => setActive(id)}
                 isActive={active === id}
               />
            )}
            </div>
            <div className={styles.content}>
              {tabItems.map(({ id, content }) => {
                return active === id ? content : ''
              })}
            </div>
           </div>
        )
      }
      
      const TabItemComponent = ({
        icon = '',
        title = '',
        onItemClicked = () => console.error('You passed no action to the component'),
        isActive = false,
      }) => {
        return (
          <div onClick={onItemClicked}>
          </div>
        )
      };

      if (isMobile){
        return (
          <div className={styles.contenedor6}>
            <div className={styles.centw}>
            <div   className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}> <b>Faction Picker</b> <img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>Spend time getting to know them, learn each others strengths and weaknesses as this will surely play in your favor in future events.</p>
                </div>
                <div  className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>Heroes of Ruy <img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>Welcome to the Genesis collection. Our first collection in the kahiru universe, which introduces the 5 factions and the 7222 warriors.</p>
                </div>
                <div className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>Reveal Day <img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>We bring together DJs from Ultra Music Festival and Tomorrowland for this unique occasion, with a streaming of more than 5 hours. We will have the opportunity to enjoy the music of: <b> (Matt Nash,
                  Corey James, Different Heaven, Crusy, TBA )</b> We are waiting for you all inside as we will have surprises for those who want to join us at the party.</p>
                </div>
                <div className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>Ruy’s Vault <img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>We will start the creation of the Kahiru community wallet. In it, we will store part of the mint and the royaltys from the secondary market sales. With it, we will carry out the actions of the future, bringing value to the whole Kahiru universe.</p>
                </div>
            </div>
          </div>
        )
      }

      else {

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: [0, 1] }} transition={{ duration: 0.8 }}>
          <div className={styles.containercol}>
            <div className={styles.divcol1}>
              <div className={styles.containercol2}>
                <div className={styles.divcol3}><Link className={styles.divcol3} to="/the-way"><img className={styles.back2} src={backr} alt=""></img></Link></div>
                <div className={styles.divcol4}><p className={styles.ptarjeta2}>DISTRICT 1</p></div>
              </div>
              <div className={styles.containertarjetas}>
                <div  style={{ width: '65%' }}  className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}> <b>Faction Picker</b> <img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>Spend time getting to know them, learn each others strengths and weaknesses as this will surely play in your favor in future events.</p>
                </div>
                <div style={{ width: '70%' }} className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>Heroes of Ruy <img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>Welcome to the Genesis collection. Our first collection in the kahiru universe, which introduces the 5 factions and the 7222 warriors.</p>
                </div>
                <div style={{ width: '80%' }} className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>Reveal Day <img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>We bring together DJs from Ultra Music Festival and Tomorrowland for this unique occasion, with a streaming of more than 5 hours. We will have the opportunity to enjoy the music of: <b> (Matt Nash,
                  Corey James, Different Heaven, Crusy, TBA )</b> We are waiting for you all inside as we will have surprises for those who want to join us at the party.</p>
                </div>
                <div style={{ width: '100%' }} className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>Ruy’s Vault <img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>We will start the creation of the Kahiru community wallet. In it, we will store part of the mint and the royaltys from the secondary market sales. With it, we will carry out the actions of the future, bringing value to the whole Kahiru universe.</p>
                </div>

              </div>
            </div>
            <div className={styles.divcol2}>
              <img className={styles.foto1} src={sel1} alt="" />
            </div>
          </div>
          
          {/* <div className={styles.container7}>
            <div className={styles.centw}>
              <h1>District 1</h1>
              <p className={styles.tabP}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Saepe quaerat suscipit omnis. Voluptatum, deleniti commodi et alias dolorem architecto neque temporibus consequatur possimus non accusamus quaerat sequi exercitationem laudantium similique.</p>
            </div>
          </div>
        <div className={styles.divgen}>
           <div className={styles.divcenter}>
              <div className={styles.container2}>
                 <motion.img onClick={(event) => { setActive(1); scrollToBottom();}} whileHover={{ opacity: 1, scale: 1.05, transition: { duration: 0.1 }, }} whileTap={{ scale: 0.9 }} className={styles.imgd1} src={vector1} alt="" />
                 <motion.img onClick={(event) => { setActive(2); scrollToBottom();}} whileHover={{ opacity: 1, scale: 1.05, transition: { duration: 0.1 }, }} whileTap={{ scale: 0.9 }} className={styles.imgd2} src={vector2} alt="" />
                 <motion.img onClick={(event) => { setActive(3); scrollToBottom();}} whileHover={{ opacity: 1, scale: 1.05, transition: { duration: 0.1 }, }} whileTap={{ scale: 0.9 }} className={styles.imgd3} src={vector3} alt="" />
              </div>
           </div> 
           <div className={styles.contenedor6}>
               <div ref={myRef} className={styles.centw}>
                <TabsComponent />
               </div>
           </div>
        </div> */}
     </motion.div>
    )}
}
