import styles from './Team.module.css';
import aitzol from './Team/Aitzol.png';
import hodei from './Team/Hodei.png';
import oceans from './Team/Icey.png';
import hildakoaB from './Team/Hildakoa.png';
import { React } from 'react';
import { useState } from 'react';
import t1 from './T1.svg';
import t2 from './T2.svg';
import t3 from './T3.svg';
import { motion } from 'framer-motion/dist/framer-motion';
import { ExternalLink } from 'react-external-link';

export function Team() {
  const isMobile = window.innerWidth <= 500;

  const tabItems = [
    {
      id: 1,
      title: 'STEP 1',
      icon: 'tabitem__icon fas fa-child',
      content: (
        <div className={styles.contenedorteam}>
          <div className={styles.tabM}>
            <h1>Aitzol</h1>
            <p className={styles.tabP}>
              He started as Creative Director and Tour manager for international DJs such as Roger Sanchez, David Guetta or Kshmr which made him travel to more than 40 countries around the world. When
              the pandemic arrived he decided to found Specter Group, a digitalization and production company, giving him access to companies such as Porsche, Maserati or Louis XIII.
            </p>
          </div>
          <div className={styles.redes}>
            <ExternalLink href="https://twitter.com/aitzolarev">
              <img src={t1} alt="" />
            </ExternalLink>
            <ExternalLink href="https://es.linkedin.com/in/aitzol-arevalo-gomez-a87836157">
              <img src={t2} alt="" />
            </ExternalLink>
          </div>
        </div>
      ),
    },
    {
      id: 2,
      title: 'STEP 2',
      icon: 'tabitem__icon fas fa-users',
      content: (
        <div className={styles.contenedorteam}>
          <div className={styles.tabM}>
            <h1>Hodei</h1>
            <p className={styles.tabP}>
              A lover of video games and the 3D world, Bl4cklotuzz began his studies with the intention of modeling projects for large companies. Standing out from the rest, he begins to learn all
              kinds of techniques on his own until he becomes a master of 3D art.
            </p>
          </div>
          <div className={styles.redes}>
            <ExternalLink href="https://twitter.com/hodeipiquer?lang=es">
              <img src={t1} alt="" />
            </ExternalLink>
            <ExternalLink href="https://es.linkedin.com/in/hodei-piquer-garcia-748b07121">
              <img src={t2} alt="" />
            </ExternalLink>
            <ExternalLink href="https://www.artstation.com/black_lotuzz">
              <img src={t3} alt="" />
            </ExternalLink>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      title: 'STEP 4',
      icon: 'tabitem__icon fas fa-money-check-alt',
      content: (
        <div className={styles.contenedorteam}>
          <div className={styles.tabM}>
            <h1>Icy</h1>
            <p className={styles.tabP}>
              With a few years of experience in the decentralized space, he has a passion for creating innovative and disruptive solutions, and is an expert in managing communities and content
              creation in the industry, attracting a growing community of users and supporters.
            </p>
          </div>
          <div className={styles.redes}>
            <ExternalLink href="https://twitter.com/Its__Icey">
              <img src={t1} alt="" />
            </ExternalLink>
          </div>
        </div>
      ),
    },
    {
      id: 5,
      title: 'STEP 4',
      icon: 'tabitem__icon fas fa-money-check-alt',
      content: (
        <div className={styles.contenedorteam}>
          <div className={styles.tabM}>
            <h1>Hildakoa</h1>
            <p className={styles.tabP}>
              After years as a developer making products for companies like Zurich, MAN and Scania, he decided to take advantage of the pandemic to create Specter Group together with Aitzol, and
              support companies in their digitalization.
            </p>
          </div>
          <div className={styles.redes}>
            <ExternalLink href="https://twitter.com/HildakoaCrypto">
              <img src={t1} alt="" />
            </ExternalLink>
            <ExternalLink href="https://www.linkedin.com/in/josu-nuñez/">
              <img src={t2} alt="" />
            </ExternalLink>
          </div>
        </div>
      ),
    },
  ];

  const [active, setActive] = useState(0);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const TabsComponent = () => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.tabs}>
          {tabItems.map(({ id, icon, title }) => (
            <TabItemComponent key={title} icon={icon} title={title} onItemClicked={() => setActive(id)} isActive={active === id} />
          ))}
        </div>
        <div className={styles.content}>
          {tabItems.map(({ id, content }) => {
            return active === id ? content : '';
          })}
        </div>
      </div>
    );
  };

  const TabItemComponent = ({ icon = '', title = '', onItemClicked = () => console.error('You passed no action to the component'), isActive = false }) => {
    return <div onClick={onItemClicked}></div>;
  };

  if (isMobile) {
    return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: [0, 1] }} transition={{ duration: 0.8 }}>
        <div className={styles.contenedor}>
          <div className={styles.hijo}>
            <motion.div className={styles.contenedor} whileHover={{ opacity: 1, transition: { duration: 0.2 } }} whileTap={{ scale: 0.9 }}>
              <img src={aitzol} alt="" />
              <div className={styles.contenedor2}>
                <div className={styles.tabM}>
                  <h1>Aitzol</h1>
                  <p className={styles.tabP}>
                  He started as Creative Director and Tour manager for international DJs such as Roger Sanchez, David Guetta or Kshmr which made him travel to more than 40 countries around the world. When
              the pandemic arrived he decided to found Specter Group, a digitalization and production company, giving him access to companies such as Porsche, Maserati or Louis XIII.
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div className={styles.contenedor} whileHover={{ opacity: 1, transition: { duration: 0.2 } }} whileTap={{ scale: 0.9 }}>
              <img src={hodei} alt="" />
              <div className={styles.contenedor2}>
                <div className={styles.tabM}>
                  <h1>Hodei</h1>
                  <p className={styles.tabP}>
                  A lover of video games and the 3D world, Bl4cklotuzz began his studies with the intention of modeling projects for large companies. Standing out from the rest, he begins to learn all
              kinds of techniques on his own until he becomes a master of 3D art.
                  </p>
                </div>
              </div>
            </motion.div>

            <motion.div className={styles.contenedor} whileHover={{ opacity: 1, transition: { duration: 0.2 } }} whileTap={{ scale: 0.9 }}>
              <img src={oceans} alt="" />
              <div className={styles.contenedor2}>
                <div className={styles.tabM}>
                  <h1>Icy</h1>
                  <p className={styles.tabP}>
                  With a few years of experience in the decentralized space, he has a passion for creating innovative and disruptive solutions, and is an expert in managing communities and content
              creation in the industry, attracting a growing community of users and supporters.
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div className={styles.contenedor} whileHover={{ opacity: 1, transition: { duration: 0.2 } }} whileTap={{ scale: 0.9 }}>
              {/* <img className={styles.imgcenter} src={hildakoa} alt="" /> */}
              <img src={hildakoaB} alt="" />
              <div className={styles.contenedor2}>
                <div className={styles.tabM}>
                  <h1>Hildakoa</h1>
                  <p className={styles.tabP}>
                  After years as a developer making products for companies like Zurich, MAN and Scania, he decided to take advantage of the pandemic to create Specter Group together with Aitzol, and
              support companies in their digitalization.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    );
  }

  // whileHover={{opacity: 1, transition: { duration: 0.2 }, }}
  // onClick={(event) => { setActive(1); scrollToBottom();}}
  else {
    return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: [0, 1] }} transition={{ duration: 0.8 }}>
        <div className={styles.contenedorimagenes}>
          <motion.div
            onClick={(event) => {
              setActive(1);
            }}
            className={active === 0 || active === 1 ? [styles.col1i] : [styles.col1io]}
            whileTap={{ scale: 0.9 }}
          >
            <img className={styles.imgcenteaitzol} src={aitzol} alt="" />
          </motion.div>
          <motion.div
            onClick={(event) => {
              setActive(2);
            }}
            className={active === 0 || active === 2 ? [styles.col1] : [styles.col1o]}
            whileTap={{ scale: 0.9 }}
          >
            <img className={styles.imgcenterhod} src={hodei} alt="" />
          </motion.div>
          <motion.div
            onClick={(event) => {
              setActive(4);
            }}
            className={active === 0 || active === 4 ? [styles.col1] : [styles.col1o]}
            whileTap={{ scale: 0.9 }}
          >
            <img className={styles.imgcenter} src={oceans} alt="" />
          </motion.div>
          <motion.div
            onClick={(event) => {
              setActive(5);
            }}
            className={active === 0 || active === 5 ? [styles.col1f] : [styles.col1fo]}
            whileTap={{ scale: 0.9 }}
          >
            {/* <img className={styles.imgcenter} src={hildakoa} alt="" /> */}
            <img className={styles.imgcenterhil} src={hildakoaB} alt="" />
          </motion.div>
        </div>
        <TabsComponent />
      </motion.div>
    );
  }
}
