import styles from "./Roadmap.module.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {Lore} from "./Lore";
import {Team} from "./Team";
import background from "./Back1.svg";
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'

/**
 * The Roadmap component.
 * @returns A React component that displays the Roadmap.
 */
export function Roadmap() {



  return (
    <div>    
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: [0, 1] }} transition={{ duration: 0.4 }}>
      <img className={styles.background1} src={background} alt="" />
        <div className={styles.text2}>
          <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.1 }, }} whileTap={{ scale: 0.9 }}>
            <Link to="/lore" component={Lore} activeClassName="active" className={styles.nolink1} ><h1 className={styles.bebas}>LORE</h1></Link>
          </motion.div>
        </div>
        <div className={styles.text3}>
          <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.1 }, }} whileTap={{ scale: 0.9 }}>
            <Link to="/team" component={Team} activeClassName="active" className={styles.nolink2} ><h1 className={styles.bebas}>TEAM</h1></Link>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}