import styles from "./LandingPage.module.css";
import circulo from "./Circulo.svg"
import kah3d from "./web1.png"
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'


/**
 * The landing page for the site.
 * @returns A React component that renders the landing page.
 */

 document.body.style.backgroundColor = "#F4EEE5";

export function LandingPage() {
  return (
    <div>
      <div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: [0, 1] }} transition={{ duration: 0.8 }}>
          <div className={styles.divcirculo}>
            <img className={styles.imgcirculo} src={circulo} alt="" />
            <img className={styles.img3d} src={kah3d} alt="" />
          </div>
        </motion.div>
      </div>
    </div>
  );
}