import styles from "./Footer.module.css";
import disclaimer from "./Disclaimer.svg";
import kahiru from "./kahiru.svg"
import discord from "./Discord.svg"
import twitter from "./Twiter.svg"
import disclaimerR from "./DisclaimerR.svg"
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";


export function Footer() {

  /**
   * Returns the current location of the page.
   * @returns The current location of the page.
   */
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  var isMobile = false

  if (window.innerWidth < 600){
      isMobile = true;
  }

  /**
   * Displays the disclaimer if the user has not accepted the disclaimer.
   * @returns None
   */
  if (splitLocation[1] == "") {
    return (
      <nav>
        <div className={styles.divdis}>
          <img className={styles.imgdis} src={disclaimer} alt="" />
        </div>
        <div className={styles.navbar}>
          <h5 className={styles.copy}>©️ 2022 Kahiru. All Rights Reserved.</h5>
        </div>
      </nav>
    );
  }

  if(splitLocation[1] == "mint" || splitLocation[1] == "raffle-check" ){
    if (!isMobile) {
      return (
        <nav>
          <div className={styles.divdis}>
            <img className={styles.imgdis} src={disclaimer} alt="" />
          </div>
          <div className={styles.navbar}>
            <h5 className={styles.copy}>©️ 2022 Kahiru. All Rights Reserved.</h5>
          </div>
        </nav>
      );
    }
    if (!isMobile) {
      return (
          <div></div>
      );
    }
  }


  // splitLocation[1] == "district1" || splitLocation[1] == "district2" || splitLocation[1] == "district3" || splitLocation[1] == "the-way" ||


  // || splitLocation[1] == "district1" || splitLocation[1] == "district2" || splitLocation[1] == "district3"

  if (splitLocation[1] == "lore" ) {
    return (
      <nav>
        <div className={styles.divdis}>
          <img className={styles.imgdis2} src={disclaimerR} alt="" />
        </div>
        <div className={styles.navbar}>
          <h5 className={styles.copybeige}>©️ 2022 Kahiru. All Rights Reserved.</h5>
        </div>
      </nav>
    );
    
  }
  if (splitLocation[1] == "kahiru") {
    return (
      <nav>
        <div className={styles.divdis}>
          <img className={styles.imgdis2} src={disclaimerR} alt="" />
        </div>
        <div className={styles.navbar}>
          <h5 className={styles.copybeige}>©️ 2022 Kahiru. All Rights Reserved.</h5>
        </div>
      </nav>
    );
    
  }
  else {
    return (
        <div></div>
    )
  }
}
