import styles from "./Districts.module.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useState } from "react";
import backr from "./Backr.svg"
import fotoblur from "./FotoBlur.png";
import sel2 from "./Sel3.svg"
import circulov from "./Circulov.svg"
import circulon from "./Circulon.svg"
import circulob from "./Circulob.svg"
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'



export function District2() {

    const isMobile = window.innerWidth <= 500;

    const tabItems = [
        {
          id: 1,
          title: 'STEP 1',
          icon: 'tabitem__icon fas fa-child',
          content: <div className={styles.tabM}><h1>Stash</h1>
          <p className={styles.tabP}>- By connecting your wallet to our website and verifying that you are a Kahiru holder, you will be able to claim Sone Particles weekly. With them, you will be able to cash in positions in different WL collaborators of Kahiru, Drawings and unique concepts never seen before from our designer Hodei and take part in faction fights. In them, you will be able to provide Stone Particles for the battle, thus coming out the winner of the fight. The victorious faction will have new benefits that we will show you soon.</p>
          </div>,
        },
        {
          id: 2,
          title: 'STEP 2',
          icon: 'tabitem__icon fas fa-users',
          content: <div className={styles.tabM}><h1>1st Prophecy</h1>
          <p className={styles.tabP}>- Holders (or those who have bought the manuscript) will have the possibility to use it, getting the cover of the Kahiru comic in NFT format. This NFT will be one of the two pieces absolutely necessary to obtain the physical comic book of Kahiru, illustrated by one of the best artists in the world. Finally, the holders of the cover of the comic will have an Airdrop inside the Kahiru tokenomics, being the first to get the Kahiru tokens and getting a voting power in the DAO.</p>
          </div>,
        },
        {
          id: 3,
          title: 'STEP 3',
          icon: 'tabitem__icon fas fa-network-wired',
          content: <div className={styles.tabM}><h1>Token</h1>
          <p className={styles.tabP}>Spread over at least 4 phases (we will publish the complete phases and tokenomics later) we present the Kahiru token. It will be used within the future Kahiru platform and ecosystem. We intend it to be the basis for a fair economy, so we encourage you to keep an eye on it once we announce its functionality.</p>
          </div>
    },
    {
        id: 4,
        title: 'STEP 3',
        icon: 'tabitem__icon fas fa-network-wired',
        content: <div className={styles.tabM}><h1>ICO</h1>
               <p className={styles.tabP}>- From Kahiru, we want to give the opportunity to people who trust us, to enter a previous phase in the purchase of the token, so one of the ways to get the token of Kahiru, 
          will be an early sale of tokens at a reduced price, giving the possibility to get more at a lower price. We also want to reward all the holders of our collection, so whoever has the comic cover, 
          as specified in the 1st Prophecy section, will receive an Airdrop of tokens in appreciation. Once claimed, that NFT will not be able to get Tokens again.</p>
        </div>
    },
    {
        id: 5,
        title: 'STEP 3',
        icon: 'tabitem__icon fas fa-network-wired',
        content: <div className={styles.tabM}><h1>DAO</h1>
        <p className={styles.tabP}>- A fair system is one in which everyone decides what to do. Therefore, we will make available to all people within the project, the possibility to vote (with a force or another depending on the tokens you have) to decide the direction of Kahiru. From what companies to collaborate with, what gifts to offer to the community, what projects to host on the platform and much more, all at the disposal of you, the community.</p>
        
        </div>
    },
    {
        id: 6,
        title: 'STEP 3',
        icon: 'tabitem__icon fas fa-network-wired',
        content: <div className={styles.tabM}><h1>The Bridge</h1>
        <p className={styles.tabP}>- One of our goals is to get known brands to become interested little by little in this new universe. To do this, we open the channels of communication, attracting brands as collaborators of Kahiru, trying to offer their services and / or benefits for our community. Get gifts just for being part of Kahiru!</p>
        </div>
    },
      ];
    
      const [active, setActive] = useState(1);

      const scrollToBottom = () =>{ 
        window.scrollTo({ 
          top: document.documentElement.scrollHeight, 
          behavior: 'smooth'
        }); 
      };
      
      const TabsComponent = () => {
        
        return (
          <div className={styles.wrapper}>
            <div className={styles.tabs}>
              {tabItems.map(({ id, icon, title }) =>
              <TabItemComponent
                 key={title}
                 icon={icon}
                 title={title}
                 onItemClicked={() => setActive(id)}
                 isActive={active === id}
               />
            )}
            </div>
            <div className={styles.content}>
              {tabItems.map(({ id, content }) => {
                return active === id ? content : ''
              })}
            </div>
           </div>
        )
      }
      
      const TabItemComponent = ({
        icon = '',
        title = '',
        onItemClicked = () => console.error('You passed no action to the component'),
        isActive = false,
      }) => {
        return (
          <div onClick={onItemClicked}>
          </div>
        )
      };

    if (isMobile){
      return (
        <div className={styles.contenedor6}>
          <div className={styles.centw}>
          <div className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>Citizens Merit<img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>From the first day of the mining, a period of 89 days will be opened to be able to ovetense the sacred manuscript. Any holder who holds his NFT for at least 30 days will be able to get it. This manuscript will be listed in opensea (with a maximum of 7222) and will be delivered to the different holders. With it, you will be able to get the cover of Kahiru's comic book.</p>
                </div>
                <div className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>Stash<img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>By connecting your wallet to our website and verifying that you are a Kahiru holder, you will be able to claim Sone Particles weekly. With them, you will be able to cash in positions in different WL collaborators of Kahiru, Drawings and unique concepts never seen before from our designer Hodei and take part in faction fights. In them, you will be able to provide Stone Particles for the battle, thus coming out the winner of the fight. The victorious faction will have new benefits that we will show you soon.</p>
                </div>
                <div className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>1st Prophecy<img style={{marginLeft: '0.7rem'}} src={circulob} alt="" /></h2>
                  <p className={styles.ptarjeta}>Holders (or those who have bought the manuscript) will have the possibility to use it, getting the cover of the Kahiru comic in NFT format. This NFT will be one of the two pieces absolutely necessary to obtain the physical comic book of Kahiru, illustrated by one of the best artists in the world. Finally, the holders of the cover of the comic will have an Airdrop inside the Kahiru tokenomics, being the first to get the Kahiru tokens and getting a voting power in the DAO.</p>
                </div>
                <div className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>The Bridge<img style={{marginLeft: '0.7rem'}} src={circulon} alt="" /></h2>
                  <p className={styles.ptarjeta}>One of our goals is to get known brands to become interested little by little in this new universe. To do this, we open the channels of communication, attracting brands as collaborators of Kahiru, trying to offer their services and / or benefits for our community. Get gifts just for being part of Kahiru!</p>
                </div>
          </div>
        </div>
      )
    }

    else {
      return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: [0, 1] }} transition={{ duration: 0.8 }}>
          <div className={styles.containercol}>
            <div className={styles.divcol1}>
              <div className={styles.containercol2}>
                <div className={styles.divcol3}><Link to="/the-way"><img className={styles.back2} src={backr} alt=""></img></Link></div>
                <div className={styles.divcol4}><p className={styles.ptarjeta2}>DISTRICT 2</p></div>
              </div>
              <div className={styles.containertarjetas}>
                <div style={{ width: '90%' }} className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>Citizens Merit<img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>From the first day of the mint, a period of 89 days will be opened to be able to claim the sacred manuscript. Any holder who holds the NFT for at least 30 days will be able to get it. This manuscript will be listed in opensea (with a maximum of 7222) and will be delivered to the different holders. With it, you will be able to get the cover of Kahiru's comic book.</p>
                </div>
                <div style={{ width: '140%' }} className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>Stash<img style={{marginLeft: '0.7rem'}} src={circulov} alt="" /></h2>
                  <p className={styles.ptarjeta}>By connecting your wallet to our website and verifying that you are a Kahiru holder, you will be able to claim Stone Particles weekly. With them, you will be able to claim positions in different WL Partners of Kahiru, Drawings and unique concepts never seen before from our designer Hodei and take part in faction fights.</p>
                </div>
                <div style={{ width: '110%' }} className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>1st Prophecy<img style={{marginLeft: '0.7rem'}} src={circulob} alt="" /></h2>
                  <p className={styles.ptarjeta}>Holders (or those who have bought the manuscript) will have the possibility to use it, getting the cover of the Kahiru comic in NFT format. This NFT will be one of the two pieces absolutely necessary to obtain the physical comic book of Kahiru. Finally, the holders of the cover of the comic will have an Airdrop inside the Kahiru tokenomics, being the first to get the Kahiru tokens and getting a voting power in the DAO.</p>
                </div>
                <div style={{ width: '125%' }} className={styles.tarjeta}>
                  <h2 className={styles.titulotarjeta}>The Bridge<img style={{marginLeft: '0.7rem'}} src={circulon} alt="" /></h2>
                  <p className={styles.ptarjeta}>One of our goals is to get known brands to become interested little by little in this new universe. To do this, we open the channels of communication, attracting brands as collaborators of Kahiru, trying to offer their services and / or benefits for our community. Get gifts just for being part of Kahiru!</p>
                </div>
              </div>
            </div>
            <div className={styles.divcol22}>
              <img className={styles.foto2} src={sel2} alt="" />
              <div className={styles.tarjetaes}>
                  <img src={fotoblur} alt="" />
                </div>
            </div>
          </div>
     </motion.div>
    )
    }
    
}
