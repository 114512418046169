import styles from "./Lore.module.css";
import ash from "./Factions/Ash.svg"
import ashw from "./Factions/AshW.svg"
import blood from "./Factions/Blood.svg"
import bloodw from "./Factions/BloodW.svg"
import soulbound from "./Factions/Soulbound.svg"
import soulboundw from "./Factions/SoulboundW.svg"
import vipers from "./Factions/Vipers.svg"
import vipersw from "./Factions/VipersW.svg"
import voidwalkers from "./Factions/Voidwalkers.svg"
import voidwalkersw from "./Factions/VoidwalkersW.svg"
import {Tabs} from "../Components/Tabs"
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'

const tabs = [
  {
    id: 1,
    name1: ash,
    name2: ashw,
    title: 'Ash',
    text: <div>
      <p className={styles.first}>The Bloodlans were difficult lands to thrive in. The villagers struggled against famine, diseases and the Lords of Akhruba day in and day out. The Lords would put down peasants without restraint and take advantage of them for their own purposes.</p>
      <p>Despite a newly promoted to Lord young Takir wanted to stop such an aberration by putting forward many suggestions to the board of trustees, nothing prevented the situation from becoming untenable, leading to the scenario that many wanted to avoid: revolution.</p>
      <p>Although the number of villagers who joined forces to fight against Akhruba's soldiers was far greater than the soldiers themselves, this was a quick battle with no options for the inhabitants.</p>
      <p>Many asked for forgiveness from the Lords, though very few were selected to return home and try to get on with their lives. The remainder were exiled out of the Bloodlands and decided to travel to the North; Asruul.</p>
      <p>Once they had crossed the great lava gorges that had been erupting continuously since they inhabited the Bloodlans, they made their way to Orosh, the only known dormant volcano remaining at the centre of the other volcanoes.</p>
      <p>As they approached the volcano, they noticed that a thin layer of glowing ash spread out from the inside. Not only did it not stain, but it created a kind of momentary electricity as it settled on the ground.</p>
      <p>They decided to enter Orosh and got down to build along its inner walls, creating structures worthy of Akhruba's finest craftsmen (many of the exiled had already learned together with them) and setting up a city within the volcano, which soon spread into the surrounding area. Protected by the circle of volcanoes called Asruul, it was a safe place to thrive in.</p>
      <p>They discovered how the ashes coming from Orosh provided them with such a physical and mental power never known before in Ruy. They discovered that the electricity had some kind of power that made them feel connected to each other and their environment when it touched the ground.</p>
    </div>
  },
  {
    id: 2,
    name1: soulbound,
    name2: soulboundw,
    title: 'Soulbound · Ink',
    text: <div>
    <p className={styles.first}>In the northern hemisphere of the planet lies the overgrown city of Rúan, where the founders of, according to the ancient scriptures of the elders, the earliest faction of the new world, remain.</p>
    <p>After years of harmony with nature, the great Erys, the first ancestral deity, brought to light that the trees sweated a dark sap with magical properties.</p>
    <p>This event would significantly change the course of history, since thanks to this power, the great masters had an exceptional command on the art of possession. Explorers who returned to the kingdom of Rúan with the wisdom of other factions passed on what they had learned to the great masters, who were given the responsibility of safeguarding the secret.</p>
    <p>The masters would then put this knowledge into books created and written from the trees of the Ruan forest. In this way, the bark was used to create the cover and the pages, and the ink, to write on them.</p>
    <p>Once the book was finished, the greatest master was able to perpetuate its knowledge.</p>
    <p>Hence, lustra of travels and writing have provided the kingdom of Rúan with a secluded peaceful village and the largest library of magical manuscripts, thus, harbouring unimaginable knowledge and power.</p>
    <p>While it is true that the great masters were not able to fully be in command of everything written in the manuscripts, they were the only ones to exploit the strengths and knowledge of all the races that inhabited Ruy. For instance, they happened to understand from how to extract the power of blood crystals, to make certain poison artefacts and to sense the power of antimatter or the electrical power of ash and earth.</p>
    <p>Many would have gone mad to possess such power, but they did comprehend that their role in this world was no other than to safeguard the course of the ages without any of the other factions, and thus all their wealth, perishing.</p>
    </div>
  },
  {
    id: 3,
    name1: voidwalkers,
    name2: voidwalkersw,
    title: 'Voidwalkers',
    text: <div>
      <p className={styles.first}>There were families of Vipers who dismissed the idea of staying in that poisoned chasm altogether, seeing friends and family perish as they fought the poison, and so they decided to turn back and wander the barren wastelands of Ido, inclining towards the suffering of the desert that showed no sympathy for the weak.</p>
      <p>There were many who did not make it through that journey, diminishing lineage and hope in equal measure. There were many discussions during the eternal journey, for many of those Scurls who had hoped for a better life had disavowed the idea, even having doubts about the decision they had made months before.</p>
      <p>Gusts of wind from the sea battered the stragglers, who had already lost several days to what had taken the lead in the crossing. They demanded water desperately, so they were determined to follow the direction of the storm-carrying wind.</p>
      <p>It was not an easy undertaking, as their gathered strength did not help them to make a three-day chase at a sufficient pace not to escape from the storm. They hoped to reach the sea as soon as possible, as many knew stories about the winds that connected two of Ruy's seas.</p>
      <p>This odyssey led them to O, a desolate place of dismal appearance, which harboured an aura of strength that instantly captivated the already reduced Scurls lineage. They decided to follow their instinct and take advantage of the land and aquifers of dubious purity to settle, so they tried to rebuild what had been taken away from them so long ago.</p>
      <p>These were difficult years. While it is true that they did not have to worry about wars or creatures to fight, the land that gave them shelter and refuge did not help the community to thrive, or at least not in the way they wanted it to. In fact, women who went into labour lived poorly for the rest of their lives, if they managed to survive the birth at all. Something abnormal happened to children born in O.</p>
    </div>
  },
  {
    id: 4,
    name1: blood,
    name2: bloodw,
    title: 'Blood',
    text: <div>
      <p className={styles.first}>Located in the bleak upland of Akhum, in Akhruba’s fortress, Bloodbrotherhood is the faction where slaughters are the order of the day.</p>
      <p>They get their power from the blood-coloured crystals (the Shhizzir) that the lands of that planet’s area produce. However, they approach a new dawn somewhat concerned, for only a year has gone by since the last Carmesí Eclipse and they have only achieved to pull out half of the Shhizzir compared to previous harvests. Even if other houses have rejected it, this is the main source of economy and power in Akhruba.</p>
      <p>Akhruba, the capital of Akhum, is located in the centre of the Oszon Valley, which cleaves through the heart of Akhum. Ancients laid the foundation stone right there since it is the only place in the valley that did not seem to possess the ability to create Shhizzirs, the blood crystals. These primevals devised a network of mines that stretched far beyond the mountains of the valley, only accessible from Ahkruba and protected by the power vested in them by the Shhizzirs.</p>
      <p>Ten Shhizzir grandmasters who had begun to fall prey to bandits and spies were appointed to guard the foundations of the city. The economy of all the lands around Akhruba was based on these crystals, and the masters had managed to unravel the art of alchemy by extracting the essence from them. This essence was used to gain power and control over the different regions in the valley. Without this power, chaos begun to reign and, of course, slaughters.</p>
      <p>These disturbances became festivals that brought together hundreds of people, for the sheer purpose of entertainment. They had lost the target for which they were initially created, a fact that the procurators of the great houses of Akhruba, who had punished nobles for participating in any festival that was not chaired by them, kept reminding the crowd. It was a very ancient trade they held in high regard, even above the leaders of the houses.</p>
    </div>
  },
  {
    id: 5,
    name1: vipers,
    name2: vipersw,
    title: 'Vipers · Poison',
    text: <div>
      <p className={styles.first}>A long time passed by since the nomads of the Ido desert had gathered together. There had been tough few years, with many of the oases they stopped at on their journeys to resupply themselves having dried up. This incident led to fierce arguments, and even some small battles between tribes.</p>
      <p>The situation became so untenable that the leaders of the great tribes of Ido agreed to answer to a board by the treaty of the Rozz in order to regulate the routes, food and resources that their own house had now taken from them.</p>
      <p>Ido is located in a very bizarre geographical spot: between the two great seas of Ruy, where once a year, a strong current enters from the West carrying a great amount of sand with it all through the desert.</p>
      <p>That year was particularly hard, as it was so dry that mountains of sand had accumulated near the sea cliffs, and when the wind came in, it would blow up such a large amount of sand that it triggered one of the biggest storms in living memory.</p>
      <p>The great board of trustees, realising they were not ready to endure such a phenomenon, run away being pushed into Abyss, an oasis which served as a boundary to announce the entrance to the great chasm of the lands of Syzil, and from which toxic gases were given off.</p>
      <p>They thought of settling and rebuilding the board on Abyss, an idea that was quickly discarded since its location did not make the land suitable for life.</p>
      <p>Realising the storm that pursued them intensified, the board leaders made a difficult decision. They would go down to Syzil with the determination of crossing the chasm.</p>
      <p>Although the bottom could not be seen from above, the descent only lasted a morning, for it was shallower than expected. Down below, they came across a completely desolate scene. Infested plantations, gas here, there and everywhere... a perfect place to pass away.</p>
      </div> 
  },

];

// document.body.style.backgroundColor = "#870025";

export function Lore() {
  return (
    <div className={styles.nav1}>
      {/* <Link to="/kahiru"><img className={styles.backbtn} src={back} alt="" /></Link> */}
      <div className={styles.center}>
        <motion.div className={styles.container1} initial={{ opacity: 0 }} animate={{ opacity: [0, 1] }} transition={{ duration: 0.4 }}>
          <Tabs tabs={tabs} />
        </motion.div>
      </div>
    </div>
  );
}