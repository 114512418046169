import styles from "./Gallery.module.css";


;

export function Gallery() {

  return (
    <div className={styles.gallery}>
    </div>
  );
}